import React from 'react';
import { connect } from 'react-redux';

import { Route, RouteProps } from 'react-router-dom';
import Redirect from './Redirect';
import Unauthorized from './Unauthorized';
import { RootState, User } from './types';

type ParentProps = {
  requiredRole?: string;
  requiredRoles?: string[];
  component: React.ComponentType;
};

type StateProps = {
  isLoggedIn: RootState['user']['isLoggedIn'];
  isFetching: RootState['user']['isFetching'];
  currentUser: RootState['user']['currentUser'];
};

function mapStateToProps({ user }: RootState): StateProps {
  return {
    isLoggedIn: user.isLoggedIn,
    isFetching: user.isFetching,
    currentUser: user.currentUser,
  };
}

type Props = ParentProps & RouteProps & StateProps;

// Idea taken from https://tylermcginnis.com/react-router-protected-routes-authentication/
function PrivateRoute(props: Props): JSX.Element {
  const {
    component: RouteComponent,
    isFetching,
    isLoggedIn,
    currentUser,
    requiredRole,
    requiredRoles,
    ...rest
  } = props;
  return (
    <Route
      {...rest}
      render={(props): JSX.Element => {
        if (isFetching === true) {
          return <div>Loading. Please wait.</div>;
        }
        if (isLoggedIn === true) {
          if (
            currentUser &&
            hasRole(currentUser, requiredRole) &&
            hasAnyRole(currentUser, requiredRoles)
          ) {
            return <RouteComponent {...props} />;
          }
          return <Unauthorized />;
        }
        return <Redirect to={`/login${getReturnTo()}}`} />;
      }}
    />
  );
}

export function hasRole(currentUser: User | {}, role?: string): boolean {
  if (!('roles' in currentUser || 'info' in currentUser)) return false;
  if (!role) return true;
  const user = currentUser as User;
  return user?.roles.indexOf(role) > -1 || user?.info?.roles.indexOf(role) > -1;
}

export function hasAnyRole(currentUser: User | {}, roles?: string[]): boolean {
  if (!('roles' in currentUser || 'info' in currentUser)) return false;
  if (!roles) return true;
  const user = currentUser as User;
  return (
    user?.roles.some(i => roles.indexOf(i) > -1) ||
    user?.info?.roles.some(i => roles.indexOf(i) > -1)
  );
}

function getReturnTo(): string {
  return window.location && window.location.pathname
    ? `?returnTo=${encodeURIComponent(window.location.pathname)}`
    : '';
}

export function combineURLs(baseURL: string, relativeURL: string): string {
  return relativeURL
    ? `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}`
    : baseURL;
}

export function getRemoteRedirect(
  baseURL: string,
  remotePath: string,
  returnURL: string = window.location.pathname,
  application?: string
): string {
  const returnTo = returnURL.startsWith('http')
    ? encodeURIComponent(returnURL)
    : encodeURIComponent(combineURLs(window.location.origin, returnURL));
  return `${baseURL}${remotePath}?returnTo=${returnTo}${
    application ? `&application=${application}` : ''
  }`;
}
export default connect(mapStateToProps)(PrivateRoute);
