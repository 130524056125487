import { inflateString, inflateNumber } from '@apps/shared/src/inflators';
import { isArrayFilled } from './typeChecks';

export const fgRepricing = 'repricing';
export const fgManualClaims = 'manualClaims';

export const claimRoles = [
  'claims-read',
  'claims-reprice',
  'claims-verify',
  'claims-clinical',
  'claims-report',
  'claims-admin',
  // 'claims-adjudicate',
  // 'claims-adjudicateverify',
  'claims-contract',
  'claims-contractsuperadmin',
];

export const tpas = [
  { id: 1, name: 'Adroit' },
  { id: 2, name: 'EBSO' },
  { id: 3, name: 'PatientAdvocates' },
  { id: 4, name: 'Preferred Benefits' },
  { id: 5, name: 'BAS' },
  { id: 6, name: 'Sana Benefits' },
  { id: 7, name: 'EBMS' },
  { id: 8, name: 'S & S Healthcare Strategies' },
  { id: 9, name: 'CAS' },
  { id: 10, name: 'RCI' },
  { id: 11, name: 'HealthSCOPE' },
  { id: 12, name: 'BML (VHBA)' },
  { id: 13, name: 'BPA' },
  { id: 14, name: 'ATA' },
  { id: 15, name: 'Continental Benefits' },
  { id: 16, name: 'Flume' },
  { id: 17, name: 'Maestro Health' },
  { id: 18, name: 'CPA' },
  { id: 20, name: 'SHA' },
  { id: 21, name: 'Loomis' },
  { id: 22, name: 'Boulder' },
  { id: 23, name: 'Cognizant' },
  { id: 24, name: 'CWI' },
  { id: 26, name: 'HealthAxis' },
  { id: 27, name: 'Healthplan' },
  { id: 30, name: 'Nova' },
  { id: 31, name: 'Paragon' },
  { id: 32, name: 'Tru Blue' },
  { id: 33, name: 'Group Benefit Services (Amwins)' },
  { id: 35, name: 'BACWV' },
  { id: 36, name: 'Asserta' },
  { id: 37, name: 'QVI Risk Solutions' },
  { id: 39, name: 'SHPG' },
  { id: 41, name: 'SISCO' },
  { id: 44, name: 'TPSC' },
  { id: 45, name: 'Auxiant' },
  { id: 46, name: 'Consociate Health' },
  { id: 47, name: 'HealthNow' },
  { id: 48, name: 'People 1st' },
  { id: 49, name: 'Aither Health' },
  { id: 50, name: 'Advisory Health' },
  { id: 51, name: 'HealthComp Holdings' },
  { id: 53, name: 'Lucent Health TN' },
  { id: 54, name: 'BeniComp' },
  { id: 56, name: 'AXA' },
  { id: 57, name: 'Heritage' },
  { id: 59, name: 'MedBen' },
  { id: 57, name: 'Heritage Consultants' },
  { id: 66, name: 'Allegiance Benefit Plan' },
  { id: 67, name: 'Acuity Group of MS' },
  { id: 68, name: 'Bywater' },
  { id: 69, name: 'UMR' },
  { id: 70, name: 'Lucent Health WI' },
  { id: 72, name: 'WellNet' },
  { id: 76, name: 'Centivo' },
  { id: 78, name: 'Professional Benefits Services' },
  { id: 79, name: 'QVI - 6DH Employees' },
  { id: 81, name: 'Alternative Risk Management, LTD.' },
  { id: 82, name: 'Enterprise Group Planning' },
  { id: 83, name: 'J.P. Farley Corporation' },
  { id: 84, name: 'Corporate Plan Management, Inc.' },
  { id: 85, name: 'Insurance Management Administrators (IMA, Inc)' },
  { id: 86, name: 'Pequot' },
  { id: 88, name: 'ClaimChoice' },
  { id: 90, name: 'OptiMed' },
  { id: 91, name: 'Sentry/Anchor' },
  { id: 92, name: 'Qualexa' },
  { id: 93, name: 'Employer Driven Insurance Services (EDIS)' },
  { id: 94, name: 'Compass Health Administrators' },
  { id: 95, name: 'Virtual Benefits Administrator (VBA)' },
  { id: 96, name: 'Coeur Plan Services dba Coeur' },
  { id: 97, name: 'Railway Health dba Arlo' },
  { id: 98, name: 'The Benefit Group, Inc.' },
  { id: 100, name: 'Triada Health' },
  { id: 101, name: 'Blackhawk Claims Service' },
  { id: 102, name: 'Diversified Benefit Administrators, Inc.' },
  { id: 103, name: 'Makina Benefits.' },
  { id: 104, name: 'Tall Tree Administrators' },
  { id: 105, name: 'Pinnacle Claims Management' },
  { id: 106, name: 'Universal Fidelity Life Insurance Co' },
  { id: 107, name: 'Assured Benefits Administrators (ABA)' },
  { id: 108, name: 'Administrative Concepts Inc.' },
  { id: 109, name: '1850 Plan Services' },
  { id: 112, name: 'TrueClaim Technologies' },
  { id: 113, name: 'Decent Inc' },
  { id: 114, name: 'Unified Health Plans' },
];

export const tpaGroups = [
  { id: 1, group: [33, 54, 13, 101, 68, 9, 102, 57, 83, 70, 53, 4, 37, 97, 95, 72, 104] },
  { id: 2, group: [14, 36, 56, 35, 96, 84, 2, 82, 16, 47, 85, 30, 3, 31, 78, 10, 8] },
  { id: 3, group: [50, 49, 66, 81, 45, 12, 94, 46, 18, 11, 17, 15, 59, 48, 86, 39, 41, 108, 76] },
  { id: 4, group: [5, 7, 93, 21, 100, 106] },
  { id: 5, group: [79] },
];

export const claimScores = {
  I1: 'I1',
  O4: 'O4',
  O3: 'O3',
  O2: 'O2',
  O1: 'O1',
  P3: 'P3',
  P2: 'P2',
  P1: 'P1',
};

export const claimScoreOrder = [
  claimScores.I1,
  claimScores.O4,
  claimScores.O3,
  claimScores.O2,
  claimScores.O1,
  claimScores.P3,
  claimScores.P2,
  claimScores.P1,
];

export function formatFloat(number, places = 2) {
  return inflateNumber(number).toFixed(places);
}

// format date string to m/d/yyyy format
export function formatDate(date) {
  const invalid = 'invalid date';
  if (typeof date !== 'string') return invalid;
  const dateObj = new Date(date);
  if (!isValidDate(dateObj)) return invalid;
  return `${dateObj.getUTCMonth() + 1}/${dateObj.getUTCDate()}/${dateObj.getUTCFullYear()}`;
}

export function isValidDate(date) {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

export function getStatementDateString(fromDate, toDate) {
  const from = fromDate ? formatDate(fromDate) : '';
  const to = toDate && toDate !== fromDate ? formatDate(toDate) : '';
  return from && to ? `${from} - ${to}` : `${from}${to}`;
}

export function dateIncludes(date, searchKey) {
  if (date == null) return false;
  return formatDate(date).includes(searchKey);
}

export function getAgeAtService(dob, dos) {
  const serviceDate = new Date(dos);
  const birthDate = new Date(dob);

  if (!isValidDate(serviceDate) || !isValidDate(birthDate)) return null;
  let age = serviceDate.getUTCFullYear() - birthDate.getUTCFullYear();
  const monthDiff = serviceDate.getUTCMonth() - birthDate.getUTCMonth();
  if (monthDiff < 0 || (monthDiff === 0 && serviceDate.getUTCDate() < birthDate.getUTCDate())) {
    age--;
  }
  return age >= 0 ? age : null;
}

export function formatName(firstName, lastName) {
  return `${inflateString(firstName)} ${inflateString(lastName)}`.trim();
}

export function formatLocation(entity) {
  if (!(entity && entity.city && entity.state && entity.zip)) return '';
  return `${entity.city}, ${entity.state} ${inflateString(entity.zip).substring(0, 5)}`.trim();
}

export function getPastMonths(monthCount = 24) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = new Date(Date.now());
  month.setDate(1); // prevents unwanted rollover when iterating months below (July 31 - 1 month = June 31 = July 1)
  const months = [];
  for (let i = 0; i < monthCount; i++) {
    months.push(`${monthNames[month.getMonth()]} ${month.getFullYear()}`);
    month.setMonth(month.getMonth() - 1);
  }
  return months;
}

export function isNumeric(str) {
  const n = parseFloat(str);
  return !Number.isNaN(n) && Number.isFinite(n);
}

export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

// converts 'camelCase' string to space-separated 'Title Case' string
export const splitCamelCase = str => {
  if (typeof str !== 'string') return '';
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
    .replace(/^\w/, c => c.toUpperCase());
};

export function capitalizeWords(str) {
  return inflateString(str).replace(/\b[a-z]/g, c => c.toUpperCase());
}

export function getBaseRoute(path) {
  return path.split('/').length > 1 ? path.split('/')[1] : '';
}

export function formatTextValue(str) {
  return str
    .trim()
    .split(' ')
    .filter(s => s)
    .join(' ');
}

export const planErrorMessages = {
  noPlans: 'Plan Names Unavailable',
  unknownPlan: 'Unknown Policy Number',
  mismatchPlan: 'Policy Number / TPA Mismatch',
  ineligible: 'Ineligible',
  multipleEligibilities:
    'Eligible under multiple plan changes. Please contact application support to resolve',
  couldNotDetermineElig: 'Could not determine eligibility',
};

export function getPlanName(policyNum, sixDegTPAID, sixDegPlanID, plans, earliestDate) {
  if (!isArrayFilled(plans)) return planErrorMessages.noPlans;
  if (!policyNum) return planErrorMessages.unknownPlan;

  if (sixDegPlanID) {
    const planWithMatchingPlanID = plans.find(plan => plan.sixDegPlanID === sixDegPlanID);
    if (planWithMatchingPlanID) return planWithMatchingPlanID.planName;
  }

  const plansWithMatchingPolicyNum = plans.filter(plan => plan.policyNum === policyNum);
  if (plansWithMatchingPolicyNum.length === 0) return planErrorMessages.unknownPlan;
  if (plansWithMatchingPolicyNum.length === 1) return plansWithMatchingPolicyNum[0].planName;

  const plansWithMatchingSixDegTPAID = plansWithMatchingPolicyNum.filter(
    plan => plan.sixDegTPAID === sixDegTPAID
  );
  if (plansWithMatchingSixDegTPAID.length === 0) return planErrorMessages.mismatchPlan;
  if (plansWithMatchingSixDegTPAID.length === 1) return plansWithMatchingSixDegTPAID[0].planName;

  if (!earliestDate) return planErrorMessages.couldNotDetermineElig;
  const earliestDateObj = new Date(earliestDate);
  const plansWithMatchingElig = plansWithMatchingSixDegTPAID.filter(plan => {
    const planStartDate = new Date(plan.startDate);
    const planTermDate = new Date(plan.termDate);
    return planStartDate <= earliestDateObj && planTermDate > earliestDateObj;
  });
  if (plansWithMatchingElig.length === 0) return planErrorMessages.ineligible;
  if (plansWithMatchingElig.length === 1) return plansWithMatchingElig[0].planName;
  return planErrorMessages.multipleEligibilities;
}
