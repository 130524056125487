import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Snackbar,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { verifyOTP, resendOTP } from '@apps/shared/src/auth/userActions';

const mapDispatchToProps = {
  verifyOTP,
  resendOTP,
};

type DispatchProps = {
  verifyOTP: (code: string, rememberMe: boolean, application: string) => void;
  resendOTP: () => void;
};

type ParentProps = {
  isMFANeeded: boolean;
  application: string;
};

type Props = ParentProps & DispatchProps;

const MFAPopup = ({ isMFANeeded, application, verifyOTP, resendOTP }: Props) => {
  const [OTP, setOTP] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(event.target.value);
  };

  const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = () => {
    if (!OTP) {
      setSnackbarMessage('Please enter the OTP');
      setSnackbarOpen(true);
      return;
    }
    verifyOTP(OTP, rememberMe, application);
  };

  const handleRequestNewCode = () => {
    resendOTP();
    setSnackbarMessage('A new OTP has been sent to your email');
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={isMFANeeded} hideBackdrop disableEscapeKeyDown>
      <DialogTitle>Multi-Factor Authentication</DialogTitle>
      <DialogContent>
        <Typography>
          We’ve sent a one-time pass code to your email address, please enter it below.
        </Typography>
        <TextField
          margin="dense"
          label="Enter Code"
          type="text"
          fullWidth
          value={OTP}
          onChange={handleCodeChange}
          inputProps={{ maxLength: 6 }}
          autoFocus
        />
        <FormControlLabel
          control={
            <Tooltip title="Checking this box will delay us from challenging for your MFA for 30 days - it is not recommended to check this box on a shared computer.">
              <Checkbox checked={rememberMe} onChange={handleRememberMeChange} color="primary" />
            </Tooltip>
          }
          label="Remember me for 30 days"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestNewCode} color="primary">
          Request New Code
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
      <Snackbar open={snackbarOpen} onClose={handleSnackbarClose} message={snackbarMessage} />
    </Dialog>
  );
};

export default connect(null, mapDispatchToProps)(MFAPopup);
