import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Modal } from '@material-ui/core';
import { KeepSessionAlive } from '@apps/shared/src/auth/userActions';
import SessionTimeoutModal from './SessionTimeoutModal';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  card: {
    maxWidth: '25rem',
    margin: 'auto',
  },
}));

export interface ChildProps {
  sessionExpired: boolean;
  stayLoggedIn: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setLastInteraction: React.Dispatch<React.SetStateAction<any>>;
}

function SessionTimeout(props: ChildProps): JSX.Element {
  const classes = useStyles();

  const stayLoggedIn = () => {
    props.setShowPopup(false);
    props.setLastInteraction(Date.now());
    localStorage.setItem('currentSessionTime', Date.now().toString());
    KeepSessionAlive();
  };

  return (
    <Modal open={props.sessionExpired} className={classes.wrapper}>
      <SessionTimeoutModal classes={classes} stayLoggedIn={stayLoggedIn} />
    </Modal>
  );
}

export default SessionTimeout;
