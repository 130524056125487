import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { colors } from '@apps/shared/src/style';
import TextInput from '../../shared/components/TextInput';
import { InputChangeHandler } from '../../shared/types/types';
import { PlanActionTypes } from './types/actions';
import CensusNames from './CensusNames';
import { MasterPlan, MasterPlanErrors } from './types/plans';

const useStyles = makeStyles({
  censusNameForm: {
    marginBottom: '25px',
  },
  chipBox: {
    marginBottom: '25px',
  },
  censusChip: {
    margin: '5px',
  },
  alertText: {
    color: colors.red,
    fontSize: '12px',
  },
  addButton: {
    backgroundColor: colors.green,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
    padding: '5px',
    margin: '10px',
    minWidth: '0px',
  },
});

type ParentProps = {
  localMasterPlan: MasterPlan;
  setLocalMasterPlan: React.Dispatch<React.SetStateAction<MasterPlan>>;
  masterPlanErrors: MasterPlanErrors;
  setPlanErrors: React.Dispatch<React.SetStateAction<MasterPlanErrors>>;
};

type Props = ParentProps;

export default function CensusNamesForm({
  localMasterPlan,
  setLocalMasterPlan,
  masterPlanErrors,
  setPlanErrors,
}: Props): JSX.Element {
  const classes = useStyles();

  const [newCensusName, setNewCensusName] = useState('');
  const [censusErrorText, setCensusErrorText] = useState('');

  const handleCensusNameUpdate: InputChangeHandler = e => {
    setCensusErrorText('');
    setNewCensusName(e.target.value.toUpperCase());
  };

  const handleAddClick = (): void => {
    if (newCensusName.length < 1) {
      setCensusErrorText('Census name must not be empty');
      return;
    }
    if (
      localMasterPlan.masterCensus &&
      localMasterPlan.masterCensus.some(name => name === newCensusName)
    ) {
      setCensusErrorText('No duplicate census names allowed');
      return;
    }
    const newCensusNames = [...(localMasterPlan.masterCensus || [])];
    if (!newCensusNames.includes(newCensusName)) {
      newCensusNames.push(newCensusName);
    }
    setLocalMasterPlan({
      ...localMasterPlan,
      masterCensus: newCensusNames,
    });

    setPlanErrors({ ...masterPlanErrors, masterCensus: '' });
    setNewCensusName('');
  };

  const handleRemoveClick = (index: number) => (): void => {
    const newCensusNames = [...(localMasterPlan.masterCensus || [])];
    newCensusNames.splice(index, 1);
    setLocalMasterPlan({
      ...localMasterPlan,
      masterCensus: newCensusNames,
    });
  };

  return (
    <div className={classes.censusNameForm}>
      <CensusNames
        masterCensus={localMasterPlan.masterCensus ? localMasterPlan.masterCensus : []}
        handleRemoveClick={handleRemoveClick}
      />
      <Typography variant="body1" className={classes.alertText}>
        {masterPlanErrors.masterCensus}
      </Typography>
      <Typography>Add Master Census:</Typography>
      <TextInput
        aria-label="Census Name"
        value={newCensusName}
        errorLabel={censusErrorText}
        onChange={handleCensusNameUpdate}
      />
      <IconButton
        className={classes.addButton}
        onClick={handleAddClick}
        data-testid="add-census-name-button"
      >
        <AddCircleIcon />
      </IconButton>
    </div>
  );
}
